
















import Vue from "vue";
import Component from "vue-class-component";
import SignInForm from "@/vue/components/SignInForm.vue";
import store from "@/store/store";
import { UserRole } from "@/model/Enums";

// https://vuejs.org/v2/guide/typescript.html

@Component({
    components: { SignInForm }
})
export default class HomeSignIn extends Vue {

    onAuthenticated(): void {
        if (store.state.signedInUser?.role == UserRole.Printer) {
            this.$router.push("/Orders");
        } else {
            this.$router.push("/Contracts");
        }
    }
}

